import { WelfareApi } from "~~/constant/api/welfare"
import { InviteActivityStatus } from "~~/constant/pages/welfare"
import http from "~~/http"
import { IComboProduct, IComboProductListObj } from "@/composables/api/combo"
import { ErrorTipType } from "@/constant/pages/landingCombo/index"

export type InviteActInfo = {
  award_credits: number
  end_timestamp: number
  invitee_award_credits: number
  activity_status: InviteActivityStatus
  start_timestamp: number
  invitee_limit: number
  award_duration_seconds?: number
  product_list?: IComboProduct
  product?: IComboProductListObj
  pm_config?: string
  tag?: ErrorTipType
}

export enum ActivityTypeEnum {
  Improvement = "complete_award",
  Invitation = "invitation", // 被邀请页面
  SecKill = "sec_kill", // 黑五秒杀
  GiftCard = "gift_card" // 黑五活动
}

export const fetchInviteActInfo = () => {
  return http.get<InviteActInfo>(WelfareApi.FetchActInfo)
}

export const fetchInviteActInfo2 = (name: ActivityTypeEnum = ActivityTypeEnum.Invitation) => {
  return http.get<InviteActInfo>(WelfareApi.FetchActInfo2, { name })
}
